// @ts-expect-error @TODO unmaintained library with no ts support
import FontName from 'fontname'
import axios from 'axios'
import fontFamilies from 'common/constants/fonts'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'
import FaqInterface from 'common/types/entities/FaqInterface'
import { LatestBlogPostsInterface } from 'common/types/entities/LatestBlogPostsInterface'
import { ButtonInterface } from 'common/types/entities/button-interface'
import { googleFontsList } from 'client/components/core/Sidebar/components/Settings/options/FontPicker/googleFontsList'
import { Variant } from 'client/components/core/Sidebar/components/Settings/options/FontPicker/googleFontsTypes'
import {
  getButtonCustomFontProperties,
  getButtonFontProperties,
} from 'client/components/entities/Button'
import {
  getContentBoxCustomFontProperties,
  getContentBoxFontProperties,
} from 'client/components/entities/ContentBox/utils/getContentBoxFontProperties'
import {
  getContentTableCustomFontProperties,
  getContentTableFontProperties,
} from 'client/components/entities/ContentTable'
import {
  getFaqCustomFontProperties,
  getFaqFontProperties,
} from 'client/components/entities/Faq'
import {
  getHeaderCustomFontProperties,
  getHeaderFontProperties,
} from 'client/components/entities/OrderBump'
import {
  getSurveyCustomFontProperties,
  getSurveyFontProperties,
} from 'client/components/entities/Survey/Survey'
import {
  getTextCustomFontProperties as getOldTextCustomFontProperties,
  getTextFontProperties,
} from 'client/components/entities/Text'
import {
  getTextCustomFontProperties,
  getTextFontProperties as getNewTextFontProperties,
} from 'client/components/entities/Text/Text'
import {
  getNewBlogPostListingCustomFontProperties,
  getNewBlogPostListingFontProperties,
} from 'client/pages/blog-page/entities/BlogPostListing'
import {
  getBreadcrumbsCustomFontProperties,
  getBreadcrumbsFontProperties,
} from 'client/pages/blog/entities/Breadcrumbs'
import {
  getButtonFontProperties as getNewButtonFontProperties,
  getNewButtonCustomFontProperties,
} from 'client/pages/contact-us/entities/contact-us-button'
import {
  getOfferPriceCustomFontProperties,
  getOfferPriceFontProperties,
} from 'client/pages/offer/entities/OfferPrice'
import { DataFile } from 'client/store/files/filesReducer'
import structureTypes from '../constants/structureTypes'
import { BreadcrumbsInterface } from '../types/entities/BreadcrumbsInterface'
import { ContentTableInterface } from '../types/entities/ContentTableInterface'
import EntityInterface, {
  BaseEntityInterface,
  CommonTextEntityInterface,
} from '../types/entities/EntityInterface'
import { OfferPriceInterface } from '../types/entities/OfferPriceInterface'
import { OldEntityInterface } from '../types/entities/OldEntityInterface'
import { SurveyInterface } from '../types/entities/SurveyInterface'
import {
  DraftJSContentInterface,
  TextInterface,
} from '../types/entities/TextInterface'
import { OrderBumpInterface } from '../types/entities/order-bump-interface'

export type UsedGoogleFonts = Required<GoogleFontProperties>[]

export type GoogleFontProperties = {
  fontFamily: string // it's possible to have
  fontStyle?: string
  fontWeight?: string
}
export type CustomFontProperties = {
  fontFamily: string // it's possible to have
  fontFileId?: number
}

export type CustomFont = {
  filePath?: string
  fontFamily: string
  fontFileId: number
}

export function generateCustomFontFacesString(usedFonts: CustomFont) {
  if (usedFonts.filePath) {
    return `@font-face {
    font-family: ${usedFonts.fontFamily};
    src: url('${usedFonts.filePath}');
  }
`
  }
}

export function generateGoogleFontFacesString(usedFonts: UsedGoogleFonts) {
  const cssArr = usedFonts.map(font => {
    const fontName = font.fontFamily.replace(/\s/g, '').toLowerCase()
    const fontWeight = `${
      font.fontWeight === '400'
        ? font.fontStyle === 'normal'
          ? 'regular'
          : ''
        : font.fontWeight
    }`
    const fileName = `${fontWeight}${
      font.fontStyle === 'italic' ? font.fontStyle : ''
    }`
    return `
          @font-face {
            font-family: "${font.fontFamily}";
            font-style: ${font.fontStyle};
            font-weight: ${font.fontWeight};
            src: url(${process.env.CLOUDFRONT_SHARED}/fonts/google-fonts/${fontName}/${fileName}.woff2) format('woff2');
          }
        `
  })
  return cssArr.join('\n')
}

export function getGoogleFontStringify(
  googleFont: Required<GoogleFontProperties>,
) {
  return `${googleFont.fontFamily}${googleFont.fontWeight}${googleFont.fontStyle}`
}

export function getFilteredGoogleFonts(
  googleFonts: UsedGoogleFonts,
): UsedGoogleFonts {
  const filteredPageGoogleFonts: UsedGoogleFonts = []
  const stringifyPageGoogleFonts: string[] = []
  googleFonts.forEach((el, idx) => {
    const fontWeight = `${
      el.fontWeight === '400'
        ? el.fontStyle === 'normal'
          ? 'regular'
          : ''
        : el.fontWeight
    }`
    const fileName = `${fontWeight}${
      el.fontStyle === 'italic' ? el.fontStyle : ''
    }` as Variant
    const font = googleFontsList.find(
      font => font.family === el.fontFamily && font.variants.includes(fileName),
    )

    if (font === undefined) {
      return
    }
    if (idx === 0) {
      filteredPageGoogleFonts.push(el)
      stringifyPageGoogleFonts.push(getGoogleFontStringify(el))
    } else {
      if (!stringifyPageGoogleFonts.includes(getGoogleFontStringify(el))) {
        filteredPageGoogleFonts.push(el)
        stringifyPageGoogleFonts.push(getGoogleFontStringify(el))
      }
    }
  })
  return filteredPageGoogleFonts
}

export function createFontFace(fontFace: string) {
  const style = document.createElement('style')
  style.setAttribute('type', 'text/css')
  style.innerHTML = fontFace
  return style
}
export function getBaseOldEntityFontProperties(entity: any) {
  const mobileFontProperties = {
    fontFamily: entity.mobileStyles.fontFamily,
    fontStyle: entity.mobileStyles.fontStyle,
    fontWeight: entity.mobileStyles.fontWeight,
  }
  const fontProperties = {
    fontFamily: entity.styles.fontFamily,
    fontStyle: entity.styles.fontStyle,
    fontWeight: entity.styles.fontWeight,
  }
  return [fontProperties, mobileFontProperties].filter(fontProperty =>
    Boolean(fontProperty.fontFamily),
  )
}

export function getBaseEntityFontProperties(entity: CommonTextEntityInterface) {
  const fontProperties = entity.fontFamily
    ? [
        {
          fontFamily: entity.fontFamily,
          fontStyle: entity.fontStyle,
          fontWeight: entity.fontWeight,
        },
      ]
    : []

  if (entity.mobileFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileFontFamily,
      fontStyle: entity.mobileFontStyle,
      fontWeight: entity.mobileFontWeight,
    })
  }

  return fontProperties
}

export function getBaseEntityCustomFontProperties(entity: any) {
  const mobileFontProperties = {
    fontFamily: entity.mobileStyles.fontFamily,
    fontFileId: entity.mobileOptions.fontFileId,
  }
  const fontProperties = {
    fontFamily: entity.styles.fontFamily,
    fontFileId: entity.options.fontFileId,
  }
  return [fontProperties, mobileFontProperties].filter(fontProperty =>
    Boolean(fontProperty.fontFamily),
  )
}

export function getBaseCustomFontProperties(entity: CommonTextEntityInterface) {
  const fontProperties = entity.fontFamily
    ? [
        {
          fontFamily: entity.fontFamily,
          fontFileId: entity.fontFileId,
        },
      ]
    : []

  if (entity.mobileFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileFontFamily,
      fontFileId: entity.mobileFontFileId,
    })
  }

  return fontProperties
}

export function getGoogleFontProperties(
  entity: OldEntityInterface | BaseEntityInterface,
): GoogleFontProperties[] {
  switch (entity.type) {
    case EntityTypeEnum.ContentTable:
      return getContentTableFontProperties(entity as ContentTableInterface)
    case structureTypes.BUTTON:
    case structureTypes.PAYMENT_BUTTON:
    case structureTypes.SALES_REDIRECTION_BUTTON:
      return getButtonFontProperties(entity)
    case EntityTypeEnum.OrderBump:
      return getBaseEntityFontProperties(entity as OrderBumpInterface)
    case structureTypes.ORDER_BUMP:
    case structureTypes.CONTENT_BOX:
      return getHeaderFontProperties(entity)
    case EntityTypeEnum.Faq:
      return getFaqFontProperties(entity as FaqInterface)
    case EntityTypeEnum.Text:
      return getNewTextFontProperties(entity as TextInterface)
    case EntityTypeEnum.Headline:
      return getNewTextFontProperties(entity as TextInterface)
    case EntityTypeEnum.OfferPrice:
      return getOfferPriceFontProperties(entity as OfferPriceInterface)
    case EntityTypeEnum.Breadcrumbs:
    case EntityTypeEnum.BlogPostDate:
    case EntityTypeEnum.BlogPostCategories:
    case EntityTypeEnum.BlogPostTitle:
    case EntityTypeEnum.Menu:
    case EntityTypeEnum.Countdown:
    case EntityTypeEnum.LanguageSwitcher:
    case EntityTypeEnum.WebinarSessionDateTime:
    case EntityTypeEnum.Product:
    case EntityTypeEnum.BlogCategoryTitle:
    case EntityTypeEnum.BlogCategoryDescription:
    case EntityTypeEnum.CustomLoginForgotPassword:
    case EntityTypeEnum.PaymentMethod:
    case EntityTypeEnum.Checkbox:
      return getBreadcrumbsFontProperties(entity as BreadcrumbsInterface)
    case structureTypes.TEXT:
    case structureTypes.BULLET_LIST:
      return getTextFontProperties(entity)
    case EntityTypeEnum.BulletList:
      return getNewTextFontProperties(entity as TextInterface)
    case EntityTypeEnum.ContentBox:
      return getContentBoxFontProperties(entity as ContentBoxInterface)
    case EntityTypeEnum.Button:
    case EntityTypeEnum.PaymentButton:
    case EntityTypeEnum.UpsellAgreeButton:
    case EntityTypeEnum.UpsellDisagreeButton:
    case EntityTypeEnum.WebinarCallToAction:
    case EntityTypeEnum.SalesRedirectionButton:
      return getNewButtonFontProperties(entity as ButtonInterface)
    case EntityTypeEnum.Survey:
      return getSurveyFontProperties(entity as SurveyInterface)
    case EntityTypeEnum.BlogPostListing:
    case EntityTypeEnum.LatestBlogPosts:
      return getNewBlogPostListingFontProperties(
        entity as BlogPostListingInterface | LatestBlogPostsInterface,
      )
    default:
      // todo ensure we always have old entity here
      return getBaseOldEntityFontProperties(entity)
  }
}

export function getCustomFontProperties(
  entity: OldEntityInterface | BaseEntityInterface,
): CustomFontProperties[] {
  switch (entity.type) {
    case EntityTypeEnum.ContentTable:
      return getContentTableCustomFontProperties(
        entity as ContentTableInterface,
      )
    case structureTypes.BUTTON:
    case structureTypes.PAYMENT_BUTTON:
    case structureTypes.SALES_REDIRECTION_BUTTON:
      return getButtonCustomFontProperties(entity)
    case EntityTypeEnum.OrderBump:
      return getBaseCustomFontProperties(entity as OrderBumpInterface)
    case structureTypes.ORDER_BUMP:
    case structureTypes.CONTENT_BOX:
      return getHeaderCustomFontProperties(entity)
    case EntityTypeEnum.ContentBox:
      return getContentBoxCustomFontProperties(entity as ContentBoxInterface)
    case EntityTypeEnum.Faq:
      return getFaqCustomFontProperties(entity as FaqInterface)
    case EntityTypeEnum.Text:
      return getTextCustomFontProperties(entity as TextInterface)
    case EntityTypeEnum.Headline:
      return getTextCustomFontProperties(entity as TextInterface)
    case EntityTypeEnum.OfferPrice:
      return getOfferPriceCustomFontProperties(entity as OfferPriceInterface)
    case EntityTypeEnum.Breadcrumbs:
    case EntityTypeEnum.BlogPostDate:
    case EntityTypeEnum.BlogPostCategories:
    case EntityTypeEnum.BlogPostTitle:
    case EntityTypeEnum.Menu:
    case EntityTypeEnum.WebinarSessionDateTime:
    case EntityTypeEnum.Product:
    case EntityTypeEnum.CustomLoginForgotPassword:
    case EntityTypeEnum.BlogCategoryTitle:
    case EntityTypeEnum.BlogCategoryDescription:
    case EntityTypeEnum.LanguageSwitcher:
    case EntityTypeEnum.Countdown:
    case EntityTypeEnum.PaymentMethod:
    case EntityTypeEnum.Checkbox:
      return getBreadcrumbsCustomFontProperties(entity as BreadcrumbsInterface)
    case structureTypes.TEXT:
    case structureTypes.BULLET_LIST:
      return getOldTextCustomFontProperties(entity)
    case EntityTypeEnum.BulletList:
      return getTextCustomFontProperties(entity as TextInterface)
    case EntityTypeEnum.Button:
    case EntityTypeEnum.PaymentButton:
    case EntityTypeEnum.UpsellAgreeButton:
    case EntityTypeEnum.UpsellDisagreeButton:
    case EntityTypeEnum.WebinarCallToAction:
    case EntityTypeEnum.SalesRedirectionButton:
      return getNewButtonCustomFontProperties(entity as ButtonInterface)
    case EntityTypeEnum.Survey:
      return getSurveyCustomFontProperties(entity as SurveyInterface)
    case EntityTypeEnum.BlogPostListing:
    case EntityTypeEnum.LatestBlogPosts:
      return getNewBlogPostListingCustomFontProperties(
        entity as BlogPostListingInterface | LatestBlogPostsInterface,
      )
    default:
      return getBaseEntityCustomFontProperties(entity)
  }
}

export function containAvertaFont(
  entities: (OldEntityInterface | EntityInterface)[],
  globalFontFamily?: string,
  globalHeadingFontFamily?: string,
) {
  const isAverta = (fontFamily?: string) => fontFamily === 'AvertaPE'
  return entities.some(
    entity =>
      getGoogleFontProperties(entity).some(font => {
        return isAverta(font.fontFamily)
      }) ||
      isAverta(globalFontFamily) ||
      isAverta(globalHeadingFontFamily),
  )
}

export function getInheritedFontsVariants(
  entities: DraftJSContentInterface[],
  globalFontWeight: string,
) {
  return entities
    .map(getInheritedFontVariants)
    .map(fontVariants => addGlobalFontWeight(fontVariants, globalFontWeight))
    .reduce((acc, fontProperties) => acc.concat(fontProperties), [])
}

export function addGlobalFontWeight(
  fontVariants: {
    fontStyle: string
    fontWeight?: string
  }[],
  globalFontWeight: string,
) {
  return fontVariants.map(el => {
    if (!el.fontWeight) {
      el.fontWeight = globalFontWeight
    }
    return el
  }) as {
    fontStyle: string
    fontWeight: string
  }[]
}

export function getEntitiesGoogleFonts(
  entities: (OldEntityInterface | BaseEntityInterface)[],
): UsedGoogleFonts {
  const googleFonts: UsedGoogleFonts = []
  entities.forEach(entity => {
    const googleFontProperties: UsedGoogleFonts = getGoogleFontProperties(
      entity,
    )
      .filter(fontProperty => isGoogleFont(fontProperty.fontFamily))
      .map(fontProperty => ({
        fontFamily: fontProperty.fontFamily,
        fontStyle: fontProperty.fontStyle || 'normal',
        fontWeight: fontProperty.fontWeight || '400',
      }))
    googleFonts.push(...googleFontProperties)
  })
  return googleFonts
}

export function getEntitiesCustomFonts(
  entities: (OldEntityInterface | BaseEntityInterface)[],
) {
  return entities.reduce((allFontsAcc, entity) => {
    const fontProperties = getCustomFontProperties(entity)

    if (fontProperties.length === 0) {
      return allFontsAcc
    }

    const entityUsedFonts = fontProperties.reduce((acc, fontProperty) => {
      if (fontProperty.fontFileId) {
        return {
          ...acc,
          [fontProperty.fontFileId]: fontProperty,
        }
      }
      return {
        ...acc,
      }
    }, allFontsAcc as CustomFont)

    return {
      ...allFontsAcc,
      ...entityUsedFonts,
    }
  }, {} as Record<number, CustomFont>)
}

export function generateCustomFontFace(pageFont: CustomFont) {
  if (Object.values(pageFont).length === 0) {
    return ''
  }
  const fontFacesString = generateCustomFontFacesString(pageFont)
  return fontFacesString
}

export function generateCustomFontFaces(pageFonts: Record<number, CustomFont>) {
  if (Object.values(pageFonts).length === 0) {
    return []
  }
  const fontFacesStrings = Object.values(pageFonts).map(el =>
    generateCustomFontFacesString(el),
  )
  return fontFacesStrings
}

export const isGoogleFont = (fontFamily: string) => {
  return fontFamilies.google.includes(fontFamily)
}

export const appendGoogleFonts = (googleFontsStr: string) => {
  const newStyle = createFontFace(googleFontsStr)
  newStyle.setAttribute('google-font', 'pending')

  if (newStyle) {
    document.head.appendChild(newStyle)
  }

  const previousLinks = document.querySelectorAll('style[google-font=true]')
  //this time need to load fonts from newStyle then we remove old style to avoid a glitch
  setTimeout(() => {
    previousLinks.forEach(style => style.remove())
  }, 500)
  //find current style and change its attribute, so we can delete it next time we change the style
  const currentLink = document.querySelector('style[google-font=pending]')
  if (currentLink) {
    currentLink.setAttribute('google-font', 'true')
  }
}

export const appendCustomFontFaces = (
  fontsProperties: Record<number, CustomFont>,
) => {
  let fontFaces = ''
  Object.values(fontsProperties).forEach(el => {
    const fontFace = generateCustomFontFace(el)
    if (fontFace) {
      fontFaces += fontFace
    }
  })
  document.head.appendChild(createFontFace(fontFaces))
}

export const entitiesWithFontOptions = [
  structureTypes.TEXT,
  EntityTypeEnum.BlogPostListing,
  EntityTypeEnum.LatestBlogPosts,
  EntityTypeEnum.BlogPostTitle,
  structureTypes.WEBINAR_SESSION_CALL_TO_ACTION,
  EntityTypeEnum.WebinarCallToAction,
  structureTypes.WEBINAR_SESSION_DATE_TIME,
  EntityTypeEnum.WebinarSessionDateTime,
  structureTypes.BULLET_LIST,
  EntityTypeEnum.BulletList,
  structureTypes.EXPLICIT_CONSENT,
  structureTypes.START_TIMER,
  structureTypes.COUNTDOWN,
  structureTypes.PHYSICAL_PRODUCT,
  EntityTypeEnum.Menu,
  structureTypes.LANGUAGE_SWITCHER,
  structureTypes.SURVEY,
  structureTypes.BUTTON,
  structureTypes.PAYMENT_BUTTON,
  structureTypes.PAYMENT_METHOD,
  EntityTypeEnum.PaymentMethod,
  structureTypes.SALES_REDIRECTION_BUTTON,
  EntityTypeEnum.SalesRedirectionButton,
  structureTypes.ORDER_BUMP,
  EntityTypeEnum.OrderBump,
  structureTypes.CONTENT_BOX,
  EntityTypeEnum.Faq,
  EntityTypeEnum.Text,
  EntityTypeEnum.Headline,
  EntityTypeEnum.ContentTable,
  EntityTypeEnum.Breadcrumbs,
  EntityTypeEnum.Button,
  EntityTypeEnum.BlogPostDate,
  EntityTypeEnum.UpsellAgreeButton,
  EntityTypeEnum.UpsellDisagreeButton,
  EntityTypeEnum.BlogPostCategories,
  EntityTypeEnum.Survey,
  EntityTypeEnum.Product,
  EntityTypeEnum.Countdown,
  EntityTypeEnum.OfferPrice,
  EntityTypeEnum.CustomLoginForgotPassword,
  EntityTypeEnum.BlogCategoryTitle,
  EntityTypeEnum.BlogCategoryDescription,
  EntityTypeEnum.ContentBox,
  EntityTypeEnum.LanguageSwitcher,
  EntityTypeEnum.Checkbox,
]

function getInheritedFontVariants(entity: DraftJSContentInterface) {
  const isBold = entity.rawContentState.includes('"style":"BOLD"')
  const isItalic = entity.rawContentState.includes('"style":"ITALIC"')
  const fontVariants = []
  if (!entity.fontFamily || entity.fontFamily === 'inherit') {
    if (isBold) {
      fontVariants.push({
        fontStyle: 'normal',
        fontWeight: '700',
      })
    }

    if (isItalic) {
      fontVariants.push({
        fontStyle: 'italic',
      })
    }

    if (isItalic && isBold) {
      fontVariants.push({
        fontStyle: 'italic',
        fontWeight: '700',
      })
    }
  }

  return fontVariants
}

export function readFontFile(buffer: Blob) {
  return new Promise<Record<string, string> | undefined>(resolve => {
    const reader = new FileReader()
    reader.onload = e => {
      if (e.target) {
        const buffer = e.target.result
        try {
          const fontMeta = FontName.parse(buffer)[0]
          resolve(fontMeta)
        } catch (e) {
          resolve(undefined)
          // FontName may throw an Error
        }
      }
    }
    reader.readAsArrayBuffer(buffer)
  })
}

export interface Font {
  id: number
  fontFamily: string
  fontWeight: string
  fullName: string
  filePath: string
  fontLabel: string
  originalFullName: string
}

export type Fonts = Font[]

export function getFontDataWithActualFontLabel(font: Font, fontList: Font[]) {
  let fontLabel = font.originalFullName
  let countPostfix = 0
  fontList.forEach((curr: Font) => {
    if (curr.originalFullName === fontLabel) {
      return countPostfix++
    }
  })
  if (countPostfix > 0) {
    fontLabel += ` (${countPostfix})`
  }
  return {
    ...font,
    fontLabel,
  }
}

export async function getFontPropertiesFromFontFiles(fontFiles: DataFile[]) {
  const objectCounter: Record<string, number> = {}
  const result = await fontFiles.reduce(
    async (acc: Promise<Fonts>, curr: DataFile) => {
      const previousAccumulator = await acc
      try {
        const response = await axios.get(curr.path, { responseType: 'blob' })
        const metaData: Record<string, string> | undefined = await readFontFile(
          response.data,
        )
        if (metaData) {
          const originalFontName = getFilteredFontName(metaData.fullName)
          let fontLabel = originalFontName

          if (objectCounter[originalFontName]) {
            fontLabel += ` (${objectCounter[fontLabel]})`
            objectCounter[originalFontName] += 1
          } else {
            objectCounter[originalFontName] = 1
          }

          const fontData = {
            id: curr.id,
            fontFamily: metaData.fontFamily,
            fontWeight: metaData.fontSubfamily,
            fullName: getFilteredFontName(metaData.fullName) + `${curr.id}`,
            originalFullName: getFilteredFontName(metaData.fullName),
            fontLabel,
            filePath: curr.path,
          }
          return [...previousAccumulator, fontData]
        } else return previousAccumulator
      } catch (e) {
        console.log('--error--', e)
        return previousAccumulator
      }
    },
    Promise.resolve([]),
  )
  return result
}

export async function validateRawCustomFontFile(file: File, mimeType: string) {
  const metaData: Record<string, string> | undefined = await readFontFile(
    new Blob([file], { type: mimeType }),
  )
  if (metaData) {
    return true
  } else return false
}

const testStringOrNumberRegExp = /[a-zA-Z]+|[0-9]+|-|_/

export function getFilteredFontName(fontName: string) {
  const arrayWithoutSpaces = fontName.replace(/\s+/g, '').split('')
  const resArray = arrayWithoutSpaces.map(character => {
    if (testStringOrNumberRegExp.test(character)) {
      return character
    } else if (character.charCodeAt(0) < 160) {
      return ''
    }
  })

  return resArray.join('')
}

const inheritFontFamilyValue = 'inherit'

export function getClearEntityWithCustomFontProperties(
  entity: OldEntityInterface | EntityInterface,
  fileId: number,
): OldEntityInterface | EntityInterface {
  switch (entity.type) {
    case EntityTypeEnum.ContentTable: {
      const typedEntity = entity as ContentTableInterface
      if (typedEntity.headerFontFileId === fileId) {
        typedEntity.headerFontFileId = undefined
        typedEntity.headerFontFamily = inheritFontFamilyValue
      }
      if (typedEntity.contentFontFileId === fileId) {
        typedEntity.contentFontFileId = undefined
        typedEntity.contentFontFamily = inheritFontFamilyValue
      }
      return typedEntity
    }
    case structureTypes.BUTTON:
    case structureTypes.PAYMENT_BUTTON:
    case structureTypes.SALES_REDIRECTION_BUTTON: {
      const typedEntity = entity as OldEntityInterface
      if (typedEntity.options.textFontFileId === fileId) {
        typedEntity.options.textFontFileId = undefined
        typedEntity.styles.fontFamily = inheritFontFamilyValue
      }
      if (typedEntity.mobileOptions.textFontFileId === fileId) {
        typedEntity.mobileOptions.textFontFileId = undefined
        typedEntity.mobileStyles.fontFamily = inheritFontFamilyValue
      }
      if (typedEntity.options.subTextFontFileId === fileId) {
        typedEntity.options.subTextFontFileId = undefined
        typedEntity.options.subTextFontFamily = inheritFontFamilyValue
      }
      if (typedEntity.mobileOptions.subTextFontFileId === fileId) {
        typedEntity.mobileOptions.subTextFontFileId = undefined
        typedEntity.mobileOptions.subTextFontFamily = inheritFontFamilyValue
      }
      return typedEntity
    }
    case structureTypes.ORDER_BUMP:
    case structureTypes.CONTENT_BOX: {
      const typedEntity = entity as OldEntityInterface
      if (typedEntity.options.fontFileId === fileId) {
        typedEntity.options.fontFileId = undefined
        typedEntity.options.headerStyles.fontFamily = inheritFontFamilyValue
      }
      if (typedEntity.mobileOptions.fontFileId === fileId) {
        typedEntity.mobileOptions.fontFileId = undefined
        typedEntity.mobileOptions.headerStyles.fontFamily =
          inheritFontFamilyValue
      }
      return typedEntity
    }
    case EntityTypeEnum.ContentBox: {
      const typedEntity = entity as ContentBoxInterface
      if (typedEntity.header.fontFileId === fileId) {
        typedEntity.header.fontFileId = undefined
        typedEntity.header.fontFamily = inheritFontFamilyValue
      }
      if (typedEntity.header.mobileFontFileId === fileId) {
        typedEntity.header.mobileFontFileId = undefined
        typedEntity.header.mobileFontFamily = inheritFontFamilyValue
      }
      return typedEntity
    }
    case EntityTypeEnum.Faq: {
      const typedEntity = entity as FaqInterface
      if (typedEntity.fontFileId === fileId) {
        typedEntity.fontFileId = undefined
        typedEntity.titleFontFamily = inheritFontFamilyValue
      }
      return typedEntity
    }
    case EntityTypeEnum.Button:
    case EntityTypeEnum.UpsellAgreeButton:
    case EntityTypeEnum.UpsellDisagreeButton:
    case EntityTypeEnum.WebinarCallToAction:
    case EntityTypeEnum.SalesRedirectionButton: {
      const typedEntity = entity as ButtonInterface
      if (typedEntity.textFontFileId === fileId) {
        typedEntity.textFontFileId = undefined
        typedEntity.textFontFamily = inheritFontFamilyValue
      }
      if (typedEntity.mobileTextFontFileId === fileId) {
        typedEntity.mobileTextFontFileId = undefined
        typedEntity.mobileTextFontFamily = inheritFontFamilyValue
      }
      if (typedEntity.subTextFontFileId === fileId) {
        typedEntity.subTextFontFileId = undefined
        typedEntity.subTextFontFamily = inheritFontFamilyValue
      }
      if (typedEntity.mobileSubTextFontFileId === fileId) {
        typedEntity.mobileSubTextFontFileId = undefined
        typedEntity.mobileSubTextFontFamily = inheritFontFamilyValue
      }
      return typedEntity
    }
    case EntityTypeEnum.Breadcrumbs:
    case EntityTypeEnum.BlogPostDate:
    case EntityTypeEnum.BlogPostCategories:
    case EntityTypeEnum.BlogPostTitle:
    case EntityTypeEnum.Menu:
    case EntityTypeEnum.WebinarSessionDateTime:
    case EntityTypeEnum.Product:
    case EntityTypeEnum.Text:
    case EntityTypeEnum.Headline:
    case EntityTypeEnum.Survey:
    case EntityTypeEnum.LanguageSwitcher:
    case EntityTypeEnum.Countdown:
    case EntityTypeEnum.Checkbox: {
      const typedEntity = entity as TextInterface
      if (typedEntity.fontFileId === fileId) {
        typedEntity.fontFileId = undefined
        typedEntity.fontFamily = inheritFontFamilyValue
      }
      if (typedEntity.mobileFontFileId === fileId) {
        typedEntity.mobileFontFileId = undefined
        typedEntity.mobileFontFamily = inheritFontFamilyValue
      }
      return typedEntity
    }
    case EntityTypeEnum.BlogPostListing:
    case EntityTypeEnum.LatestBlogPosts: {
      const typedEntity = entity as BlogPostListingInterface
      if (typedEntity.titleFontFileId === fileId) {
        typedEntity.titleFontFileId = undefined
        typedEntity.titleFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.mobileTitleFontFileId === fileId) {
        typedEntity.mobileTitleFontFileId = undefined
        typedEntity.mobileTitleFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.categoriesFontFileId === fileId) {
        typedEntity.categoriesFontFileId = undefined
        typedEntity.categoriesFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.mobileCategoriesFontFileId === fileId) {
        typedEntity.mobileCategoriesFontFileId = undefined
        typedEntity.mobileCategoriesFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.descriptionFontFileId === fileId) {
        typedEntity.descriptionFontFileId = undefined
        typedEntity.descriptionFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.mobileDescriptionFontFileId === fileId) {
        typedEntity.mobileDescriptionFontFileId = undefined
        typedEntity.mobileDescriptionFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.dateFontFileId === fileId) {
        typedEntity.dateFontFileId = undefined
        typedEntity.dateFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.mobileDateFontFileId === fileId) {
        typedEntity.mobileDateFontFileId = undefined
        typedEntity.mobileDateFontFamily = inheritFontFamilyValue
      }

      return typedEntity
    }
    case EntityTypeEnum.OfferPrice: {
      const typedEntity = entity as OfferPriceInterface

      if (typedEntity.nameFontFileId === fileId) {
        typedEntity.nameFontFileId = undefined
        typedEntity.nameFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.mobileNameFontFileId === fileId) {
        typedEntity.mobileNameFontFileId = undefined
        typedEntity.mobileNameFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.descriptionFontFileId === fileId) {
        typedEntity.descriptionFontFileId = undefined
        typedEntity.descriptionFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.mobileDescriptionFontFileId === fileId) {
        typedEntity.mobileDescriptionFontFileId = undefined
        typedEntity.mobileDescriptionFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.amountFontFileId === fileId) {
        typedEntity.amountFontFileId = undefined
        typedEntity.amountFontFamily = inheritFontFamilyValue
      }

      if (typedEntity.mobileAmountFontFileId === fileId) {
        typedEntity.mobileAmountFontFileId = undefined
        typedEntity.mobileAmountFontFamily = inheritFontFamilyValue
      }

      return typedEntity
    }
    default: {
      const typedEntity = entity as OldEntityInterface
      if (typedEntity.options && typedEntity.options.fontFileId === fileId) {
        typedEntity.options.fontFileId = undefined
        typedEntity.styles.fontFamily = inheritFontFamilyValue
      }
      if (
        typedEntity.mobileOptions &&
        typedEntity.mobileOptions.fontFileId === fileId
      ) {
        typedEntity.mobileOptions.fontFileId = undefined
        typedEntity.mobileStyles.fontFamily = inheritFontFamilyValue
      }
      return typedEntity
    }
  }
}
