import {
  ContentBlock,
  convertFromRaw,
  DraftEditorCommand,
  DraftHandleValue,
  Editor,
  EditorState,
  KeyBindingUtil,
  RichUtils,
} from 'draft-js'
import React, { memo, useEffect, useState } from 'react'
import { HeadingTypeEnum } from 'common/enums/HeadingTypeEnum'
import { AlignType, PaddingType } from 'common/types/styleTypes'
import { isRgba } from 'common/utils/colorUtils'
import * as textUtils from '../TextEditor/utils'
import Menu from './Menu'
import Color from './Menu/ColorPicker'
import HeadingControls, { HeadingTypeStyles } from './Menu/HeadingControls'
import InlineStyleControls from './Menu/InlineStyleControls'
import Link, { LinkProps, LinkType } from './Menu/Link'
import MenuButtonUi from './Menu/ui/MenuButtonUi'
import createLinkDecorator from './linkDecorator'
import LinkEditOverlayUi from './ui/LinkEditOverlayUi'
import TextEditorUi from './ui/TextEditorUi'
import * as utils from './utils'
import {
  filterBlockTypes,
  filterInlineStyles,
  toggleParentDraggableAttribute,
} from './utils'

export type TextEditorUpdatePayload = {
  rawContentState?: string
  fontSize?: number
  mobileFontSize?: number
  lineHeight?: number
  mobileLineHeight?: number
}

type TextEditorProps = {
  isEditing: boolean
  rawContentState: string
  linkColor?: string
  textColor?: string
  update: (payload: Partial<TextEditorUpdatePayload>) => void
  padding?: PaddingType
  mobilePadding?: PaddingType
  textAlign: AlignType // we use common TextUi and this is just for type checking
  editorTextAlign?: AlignType // because draftJS has own align logic, it can't be applied outside
  lineHeight?: number
  mobileLineHeight?: number
  fontFamily?: string
  mobileFontFamily?: string
  fontSize?: number
  mobileFontSize?: number
  fontStyle?: string
  mobileFontStyle?: string
  fontWeight?: string
  mobileFontWeight?: string
  blockStyleFn?: (block: ContentBlock) => any
  enableHeadings?: boolean
  attrId: string
  backgroundColor?: string
  mobileBackgroundColor?: string
  letterSpacing?: number
  mobileLetterSpacing?: number
  LinkMenu?: React.FC<LinkProps>
  blockRendererFn?: (contentBlock: ContentBlock) => any
  handleReturn?: (
    e: React.KeyboardEvent<HTMLElement>,
    editorState: EditorState,
  ) => EditorState | undefined
}

function TextEditor({
  attrId,
  isEditing,
  rawContentState,
  linkColor,
  textColor,
  fontSize,
  mobileFontSize,
  fontStyle,
  mobileFontStyle,
  fontWeight,
  mobileFontWeight,
  update,
  padding,
  mobilePadding,
  editorTextAlign,
  textAlign,
  lineHeight,
  mobileLineHeight,
  fontFamily,
  mobileFontFamily,
  blockStyleFn,
  enableHeadings = false,
  backgroundColor,
  mobileBackgroundColor,
  letterSpacing,
  mobileLetterSpacing,
  handleReturn,
  blockRendererFn,
  LinkMenu = Link,
}: // blockRenderMap,
TextEditorProps) {
  const editor = React.useRef<Editor>(null)
  const [editorState, setEditorState] = useState(
    utils.createStateFromRaw(
      rawContentState,
      createLinkDecorator(handleLinkClick),
    ),
  )
  const [linkPrevColor] = useState('')
  const [currentLinkKey, setCurrentLinkKey] = useState('')
  const linkSettingsEnabled = Boolean(currentLinkKey)

  const focusEditor = React.useCallback(() => {
    if (editor.current) {
      toggleParentDraggableAttribute(false)
      editor.current.focus()
    }
  }, [editor])

  function handleLinkClick(entityKey: string) {
    setCurrentLinkKey(entityKey)
    focusEditor()
  }

  React.useEffect(() => {
    setEditorState(prev =>
      EditorState.set(prev, {
        currentContent: convertFromRaw(JSON.parse(rawContentState)),
      }),
    )
  }, [rawContentState])

  React.useEffect(() => {
    if (currentLinkKey) {
      setEditorState(prev =>
        EditorState.set(prev, {
          decorator: createLinkDecorator(handleLinkClick),
        }),
      )
    }
    // eslint-disable-next-line
  }, [currentLinkKey])

  function toggleInlineStyle(inlineStyle: string) {
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle)
    setEditorState(newState)
  }

  // function toggleBlockType(blockType: string) {
  //   const newEditorState = RichUtils.toggleBlockType(editorState, blockType)
  //   setEditorState(newEditorState)
  // }

  function handleEditorStateChange(nextState: EditorState) {
    let filteredState = nextState

    const shouldFilterPaste =
      nextState.getCurrentContent() !== editorState.getCurrentContent() &&
      nextState.getLastChangeType() === 'insert-fragment'

    if (shouldFilterPaste) {
      let filteredContentState = filterInlineStyles(
        ['BOLD', 'ITALIC', 'STRIKETHROUGH', 'UNDERLINE', '^rgba'],
        filteredState.getCurrentContent(),
      )

      filteredContentState = filterBlockTypes(
        ['unordered-list-item'],
        filteredContentState,
      )

      filteredState = EditorState.set(filteredState, {
        currentContent: filteredContentState,
      })
    }
    setEditorState(filteredState)
  }

  function changeColor(color: string) {
    const updatedState = utils.toggleInlineStyle(editorState, color, isRgba)
    setEditorState(updatedState)
    // save method would take old state
    update({ rawContentState: utils.getRawContentState(updatedState) })
  }

  function createOrEditLink(event: React.SyntheticEvent) {
    event.preventDefault()
    const entityKey = utils.getSelectionEntityKeyOrNull(editorState)

    if (
      entityKey &&
      editorState.getCurrentContent().getEntity(entityKey).getType() === 'LINK'
    ) {
      setCurrentLinkKey(entityKey)
    } else {
      const selectedEditorState = utils.selectCurrentWord(editorState)
      const createLinkResult = utils.createLink(selectedEditorState)
      setEditorState(createLinkResult.editorState)
      setCurrentLinkKey(createLinkResult.linkKey)
    }
  }

  function updateLink(data: LinkType) {
    const newContentState = editorState
      .getCurrentContent()
      .mergeEntityData(currentLinkKey, data)

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-block-data',
    )
    setEditorState(newEditorState)
    save()
  }

  function closeLinkSettings() {
    removeLinkIfNoUrlAndPopup()
  }

  function removeLinkIfNoUrlAndPopup() {
    const linkData = utils.getEntityData(
      editorState,
      currentLinkKey,
    ) as LinkType
    if (!linkData.url && !linkData.popupId) {
      removeCurrentLink()
    }
    setCurrentLinkKey('')
  }

  function closeLinkSettingsAndStopPropagation(e: React.SyntheticEvent) {
    e.stopPropagation()
    removeLinkIfNoUrlAndPopup()
  }

  function handleBlur() {
    save()
  }

  function save() {
    const changedContentState = editorState.getCurrentContent()
    const initialContentState = convertFromRaw(JSON.parse(rawContentState))
    const equals = initialContentState
      .getBlockMap()
      .equals(changedContentState.getBlockMap())
    if (!equals) {
      update({ rawContentState: utils.getRawContentState(editorState) })
    }
  }

  function removeCurrentLink() {
    let updatedEditorState = utils.removeLink(editorState, currentLinkKey)
    if (updatedEditorState) {
      // restore prev color
      updatedEditorState = RichUtils.toggleInlineStyle(
        updatedEditorState,
        linkPrevColor,
      )
      setEditorState(updatedEditorState)
    }
  }

  function removeCurrentLinkAndCloseSettings() {
    removeCurrentLink()
    setCurrentLinkKey('')
  }

  function toggleHeadingType(
    styles: HeadingTypeStyles,
    mobileStyles: HeadingTypeStyles,
    headingType: HeadingTypeEnum,
  ) {
    update({
      fontSize: styles.fontSize,
      lineHeight: styles.lineHeight,
      mobileFontSize: mobileStyles.fontSize,
      mobileLineHeight: mobileStyles.lineHeight,
    })
    setEditorState(RichUtils.toggleBlockType(editorState, headingType))
  }

  function handleKeyCommand(
    command: DraftEditorCommand,
    editorState: EditorState,
  ) {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      setEditorState(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  function handleEditorReturn(
    e: React.KeyboardEvent<HTMLElement>,
    editorState: EditorState,
  ): DraftHandleValue {
    if (KeyBindingUtil.isSoftNewlineEvent(e)) {
      handleEditorStateChange(RichUtils.insertSoftNewline(editorState))
      return 'handled'
    }

    if (handleReturn) {
      const handledEditorState = handleReturn(e, editorState)
      if (handledEditorState) {
        handleEditorStateChange(handledEditorState)
        return 'handled'
      }
    }

    return 'not-handled'
  }

  useEffect(() => {
    if (!isEditing) {
      toggleParentDraggableAttribute(true)
    }
  }, [isEditing])

  // NOTE: Needs for change icon in bullet-list, force-update editor
  useEffect(() => {
    if (blockRendererFn) {
      focusEditor()
    }
  }, [blockRendererFn])

  return (
    <>
      {isEditing && (
        <Menu extended={enableHeadings || linkSettingsEnabled}>
          {currentLinkKey ? (
            <LinkMenu
              remove={removeCurrentLinkAndCloseSettings}
              close={closeLinkSettings}
              currentLink={
                utils.getEntityData(editorState, currentLinkKey) as LinkType
              }
              update={updateLink}
            />
          ) : (
            <>
              {enableHeadings && (
                <HeadingControls
                  onToggle={toggleHeadingType}
                  currentType={textUtils.getCurrentBlockType(editorState)}
                />
              )}
              <InlineStyleControls
                currentStyle={editorState.getCurrentInlineStyle()}
                toggleStyle={toggleInlineStyle}
              />
              <Color
                color={utils.getCurrentColor(editorState)}
                onChange={changeColor}
              />
              <MenuButtonUi onClick={createOrEditLink}>
                <span className="fas fa-link" />
              </MenuButtonUi>
            </>
          )}
        </Menu>
      )}
      <TextEditorUi
        id={attrId}
        onClick={focusEditor}
        onBlur={handleBlur}
        isEditing={isEditing}
        textAlign={textAlign}
        padding={padding}
        mobilePadding={mobilePadding}
        lineHeight={lineHeight}
        mobileLineHeight={mobileLineHeight}
        fontFamily={fontFamily}
        mobileFontFamily={mobileFontFamily}
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        fontStyle={fontStyle}
        mobileFontStyle={mobileFontStyle}
        fontWeight={fontWeight}
        mobileFontWeight={mobileFontWeight}
        linkColor={linkColor}
        color={textColor}
        backgroundColor={backgroundColor}
        mobileBackgroundColor={mobileBackgroundColor}
        letterSpacing={letterSpacing}
        mobileLetterSpacing={mobileLetterSpacing}
        enableHeadings={enableHeadings}
      >
        <Editor
          ref={editor}
          customStyleFn={utils.applyCustomStyle}
          editorState={editorState}
          onChange={handleEditorStateChange}
          handleKeyCommand={handleKeyCommand}
          textAlignment={editorTextAlign}
          blockStyleFn={blockStyleFn}
          blockRendererFn={blockRendererFn}
          handleReturn={handleEditorReturn}
        />
        {currentLinkKey && (
          <LinkEditOverlayUi onClick={closeLinkSettingsAndStopPropagation} />
        )}
      </TextEditorUi>
    </>
  )
}

export default memo(TextEditor)
