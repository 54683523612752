import React from 'react'
import { slugs } from 'common/constants/inputTypes'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import ColumnOld from 'publisher/components/ColumnOld'
import ContentBoxOld from 'publisher/components/ContentBoxOld'
import RowOld from 'publisher/components/RowOld'
import VideoSessionVideo from 'publisher/components/VideoSessionVideo'
import Agreement from 'publisher/components/entities/Agreement'
import NewAgreement from 'publisher/components/entities/Agreement/Agreement'
import Audio from 'publisher/components/entities/Audio'
import AudioNew from 'publisher/components/entities/AudioNew'
import BlogContentPlaceholder from 'publisher/components/entities/BlogContentPlaceholder'
import BlogContentPlaceholderNew from 'publisher/components/entities/BlogContentPlaceholderNew'
import BlogPostBody from 'publisher/components/entities/BlogPostBody'
import BlogPostContentPlaceholder from 'publisher/components/entities/BlogPostContentPlaceholder'
import BlogPostContentPlaceholderNew from 'publisher/components/entities/BlogPostContentPlaceholderNew'
import BodyOld from 'publisher/components/entities/BodyOld'
import BulletList from 'publisher/components/entities/BulletList'
import Checkbox from 'publisher/components/entities/Checkbox'
import ContentTable from 'publisher/components/entities/ContentTable'
import Countdown from 'publisher/components/entities/Countdown'
import CountdownOld from 'publisher/components/entities/CountdownOld'
import Coupon from 'publisher/components/entities/Coupon'
import CustomerType from 'publisher/components/entities/CustomerType'
import ExplicitConsent from 'publisher/components/entities/ExplicitConsent'
import FacebookComments from 'publisher/components/entities/FacebookComments'
import BaseField from 'publisher/components/entities/Field/BaseField'
import CompanyNameField from 'publisher/components/entities/Field/CompanyNameField'
import CountryField from 'publisher/components/entities/Field/CountryField'
import EmailField from 'publisher/components/entities/Field/EmailField'
import PhoneNumberField from 'publisher/components/entities/Field/PhoneNumberField'
import Image from 'publisher/components/entities/Image'
import Inline from 'publisher/components/entities/Inline'
import LanguageSwitcher from 'publisher/components/entities/LanguageSwitcher'
import Menu from 'publisher/components/entities/Menu'
import NewBulletList from 'publisher/components/entities/NewBulletList'
import NewCustomLogin from 'publisher/components/entities/NewCustomLogin'
import NewHorizontalLine from 'publisher/components/entities/NewHorizontalLine'
import OfferThankYouOrderSummaryNew from 'publisher/components/entities/OfferThankYouOrderSummaryNew'
import OptInButton from 'publisher/components/entities/OptInButton'
import PaymentButton from 'publisher/components/entities/PaymentButton'
import PaymentButtonNew from 'publisher/components/entities/PaymentButtonNew'
import PaymentMethod from 'publisher/components/entities/PaymentMethod'
import Popup from 'publisher/components/entities/Popup'
import RawHtml from 'publisher/components/entities/RawHtml'
import RemotePopup from 'publisher/components/entities/RemotePopup'
import Section from 'publisher/components/entities/Section'
import SectionOld from 'publisher/components/entities/Section/SectionOld'
import ShippingFees from 'publisher/components/entities/ShippingFees'
import StartTimer from 'publisher/components/entities/StartTimer'
import Survey from 'publisher/components/entities/Survey'
import SurveyNew from 'publisher/components/entities/Survey/SurveyNew'
import Text from 'publisher/components/entities/Text'
import TextNew from 'publisher/components/entities/TextNew'
import TwitterTweetButton from 'publisher/components/entities/TwitterTweetButton'
import TwoStepPaymentForm from 'publisher/components/entities/TwoStepPaymentForm'
import VideoNew from 'publisher/components/entities/Video'
import WebinarSessionCallToAction from 'publisher/components/entities/WebinarCallToAction'
import WebinarSessionDateTime from 'publisher/components/entities/WebinarSessionDateTime'
import { BlogPostLayoutBody } from 'publisher/components/entities/blog-post-layout-body'
import { Body } from 'publisher/components/entities/body'
import BookingCalendar from 'publisher/components/entities/booking-calendar/index'
import { PreviewBookingCalendar } from 'publisher/components/entities/booking-calendar/preview-booking-calendar'
import { RescheduleBookingCalendar } from 'publisher/components/entities/booking-calendar/reschedule-booking-calendar'
import OptInButtonNew from 'publisher/components/entities/button-new'
import InlineBody from 'publisher/components/entities/inline-body'
import RemotePopupBody from 'publisher/components/entities/remote-popup-body'
import ImageContainer from 'publisher/containers/ImageContainer'
import OrderBumpContainer from 'publisher/containers/OrderBumpContainer'
import BlogPostCategoriesNew from 'publisher/pages/blog-layout/components/BlogPostCategoriesNew'
import BlogPostDateNew from 'publisher/pages/blog-layout/components/BlogPostDateNew'
import BlogPostListingNew from 'publisher/pages/blog-page/components/BlogPostListingNew'
import LatestBlogPostsCarousel from 'publisher/pages/blog-page/components/LatestBlogPostsCarousel'
import LatestBlogPostsNew from 'publisher/pages/blog-page/components/LatestBlogPostsNew'
import TaxNumber from 'publisher/pages/offer-page/components/TaxNumber'
import BlogCategoryDescription from '../../components/entities/BlogCategoryDescription'
import BlogCategoryTitle from '../../components/entities/BlogCategoryTitle'
import BlogPostImage from '../../components/entities/BlogPostImage'
import Breadcrumbs from '../../components/entities/Breadcrumbs'
import Carousel from '../../components/entities/Carousel'
import Column from '../../components/entities/Column'
import ContentBox from '../../components/entities/ContentBox'
import CustomLoginForgotPassword from '../../components/entities/CustomLoginForgotPassword'
import Faq from '../../components/entities/Faq'
import PasswordField from '../../components/entities/Field/PasswordField'
import Headline from '../../components/entities/Headline'
import NewLanguageSwitcher from '../../components/entities/NewLanguageSwitcher'
import NewRawHtml from '../../components/entities/NewRawHtml'
import OptInRecaptcha from '../../components/entities/OptInRecaptcha'
import PricePlanCalculator from '../../components/entities/PricePlanCalculator'
import Row from '../../components/entities/Row'
import DownsellPaymentButton from '../../pages/UpsellPage/components/DownsellPaymentButton'
import UpsellPaymentButtonNew from '../../pages/UpsellPage/components/UpsellPaymentButton'
import BlogPostTitleNew from '../../pages/blog-layout/components/BlogPostTitleNew'
import Attachments from '../../pages/contact-us/components/Attachments'
import ContactUs from '../../pages/contact-us/components/ContactUs'
import ContactUsButton from '../../pages/contact-us/components/ContactUsButton'
import ContactUsField from '../../pages/contact-us/components/ContactUsField'
import Recaptcha from '../../pages/contact-us/components/Recaptcha'
import TextArea from '../../pages/contact-us/components/TextArea'
import CustomerTypeNew from '../../pages/offer-page/components/CustomerType'
import OfferPrice from '../../pages/offer-page/components/OfferPrice'
import PaymentMethodNew from '../../pages/offer-page/components/PaymentMethod'
import Product from '../../pages/offer-page/components/Product'
import OrderBump from '../../pages/offer-page/components/order-bump'
import SalesRedirectionButtonNew from '../../pages/sale-page/components/SalesRedirectionButton'
import WebinarRegistrationDate from '../../pages/webinar-registration/components/WebinarRegistrationDate'
import WebinarSessionCallToActionNew from '../../pages/webinar-session/components/WebinarCallToActionNew'
import WebinarSessionVideoNew from '../../pages/webinar-session/components/WebinarSessionVideoNew'
import WebinarSessionLinkNew from '../../pages/webinar-thank-you/components/WebinarSessionLinkNew'
import BlogPageBody from 'publisher/components/entities/blog-page-body'

export default function createEntityElement() {
  return (entity, additionalProps) => {
    const key = `entity-${entity.id}`
    switch (entity.type) {
      case structureTypes.BODY:
      case structureTypes.BLOG_PAGE_BODY:
      case structureTypes.BLOG_POST_LAYOUT_BODY:
        return <BodyOld entity={entity} key={key} />
      case EntityTypeEnum.Body:
        return <Body entity={entity} key={key} />
      case EntityTypeEnum.BlogPageBody:
        return <BlogPageBody entity={entity} key={key} />
      case EntityTypeEnum.BlogPostLayoutBody:
        return <BlogPostLayoutBody entity={entity} key={key} />
      case structureTypes.SECTION:
        return <SectionOld entity={entity} key={key} />
      case EntityTypeEnum.Section:
        return <Section entity={entity} key={key} />
      case structureTypes.ROW:
        return <RowOld entity={entity} key={key} />
      case EntityTypeEnum.Row:
        return <Row entity={entity} key={key} />
      case structureTypes.COLUMN:
        return <ColumnOld entity={entity} key={key} {...additionalProps} />
      case EntityTypeEnum.Column:
        return <Column entity={entity} key={key} {...additionalProps} />
      case structureTypes.TEXT:
        return <Text entity={entity} key={key} />
      case structureTypes.BULLET_LIST:
        return <BulletList entity={entity} key={key} />
      case EntityTypeEnum.BulletList:
        return <NewBulletList entity={entity} key={key} />
      case EntityTypeEnum.BookingCalendar:
        return <BookingCalendar entity={entity} key={key} />
      case EntityTypeEnum.BookingCalendarPreview:
        return <PreviewBookingCalendar entity={entity} key={key} />
      case EntityTypeEnum.BookingCalendarReschedule:
        return <RescheduleBookingCalendar entity={entity} key={key} />
      case structureTypes.BUTTON:
        return <OptInButton entity={entity} key={key} />
      case structureTypes.FORM_INPUT:
        switch (entity.options.slug) {
          case slugs.EMAIL:
            return <EmailField entity={entity} />
          case slugs.COUNTRY:
            return <CountryField entity={entity} />
          case slugs.PHONE_NUMBER:
            return <PhoneNumberField entity={entity} />
          case slugs.TAX_NUMBER:
            return <TaxNumber entity={entity} />
          case slugs.COMPANY_NAME:
            return <CompanyNameField entity={entity} />
          case slugs.PASSWORD:
          case slugs.CONFIRM_PASSWORD:
            return <PasswordField entity={entity} />
          default:
            return <BaseField entity={entity} />
        }
      case EntityTypeEnum.Button:
        return <OptInButtonNew entity={entity} key={key} />
      case structureTypes.IMAGE:
        return <ImageContainer entity={entity} key={key} />
      case structureTypes.POPUP:
        return <Popup entity={entity} key={key} {...additionalProps} />
      case structureTypes.REMOTE_POPUP:
        return <RemotePopup entity={entity} key={key} {...additionalProps} />
      case EntityTypeEnum.RemotePopupBody:
        return (
          <RemotePopupBody entity={entity} key={key} {...additionalProps} />
        )
      case structureTypes.INLINE:
        return <Inline entity={entity} key={key} />
      case EntityTypeEnum.InlineBody:
        return <InlineBody entity={entity} key={key} />
      case structureTypes.AUDIO:
        return <Audio entity={entity} key={key} />
      case structureTypes.CONTENT_BOX:
        return <ContentBoxOld entity={entity} key={key} />
      case EntityTypeEnum.ContentBox:
        return <ContentBox entity={entity} key={key} />
      case EntityTypeEnum.FacebookComments:
        return <FacebookComments entity={entity} key={key} />
      case EntityTypeEnum.WebinarSessionLink:
        return <WebinarSessionLinkNew entity={entity} key={key} />
      case structureTypes.WEBINAR_SESSION_DATE_TIME:
        return <WebinarSessionDateTime entity={entity} key={key} />
      case structureTypes.WEBINAR_SESSION_CALL_TO_ACTION:
        return <WebinarSessionCallToAction entity={entity} key={key} />
      case EntityTypeEnum.WebinarCallToAction:
        return <WebinarSessionCallToActionNew entity={entity} key={key} />
      case structureTypes.WEBINAR_SESSION_VIDEO:
        return <VideoSessionVideo entity={entity} key={key} />
      case structureTypes.PAYMENT_METHOD:
        return <PaymentMethod entity={entity} key={key} />
      case EntityTypeEnum.PaymentMethod:
        return <PaymentMethodNew entity={entity} key={key} />
      case structureTypes.CUSTOMER_TYPE:
        return <CustomerType entity={entity} key={key} />
      case EntityTypeEnum.OfferPrice:
        return <OfferPrice entity={entity} key={key} />
      case structureTypes.RAW_HTML:
        return <RawHtml entity={entity} key={key} />
      case EntityTypeEnum.UpsellAgreeButton:
        return <UpsellPaymentButtonNew entity={entity} key={key} />
      case EntityTypeEnum.UpsellDisagreeButton:
        return <DownsellPaymentButton entity={entity} key={key} />
      case structureTypes.PAYMENT_BUTTON:
        return <PaymentButton entity={entity} key={key} />
      case EntityTypeEnum.PaymentButton:
        return <PaymentButtonNew entity={entity} key={key} />
      case structureTypes.ORDER_BUMP:
        return <OrderBumpContainer entity={entity} key={key} />
      case structureTypes.EXPLICIT_CONSENT:
        return <ExplicitConsent entity={entity} key={key} />
      case EntityTypeEnum.Checkbox:
        return <Checkbox entity={entity} key={key} />
      case structureTypes.START_TIMER:
        return <StartTimer entity={entity} key={key} />
      case structureTypes.AGREEMENT:
        return <Agreement entity={entity} key={key} />
      case EntityTypeEnum.OrderSummary:
        return <OfferThankYouOrderSummaryNew entity={entity} key={key} />
      case structureTypes.COUNTDOWN:
        return <CountdownOld entity={entity} key={key} />
      case EntityTypeEnum.Countdown:
        return <Countdown entity={entity} key={key} />
      case EntityTypeEnum.CustomLogin:
        return <NewCustomLogin entity={entity} key={key} />
      case EntityTypeEnum.CustomLoginForgotPassword:
        return <CustomLoginForgotPassword entity={entity} key={key} />
      case structureTypes.PHYSICAL_PRODUCT:
        return <div />
      case EntityTypeEnum.Product:
        return <Product entity={entity} key={key} />
      case EntityTypeEnum.SalesRedirectionButton:
        return <SalesRedirectionButtonNew entity={entity} key={key} />
      case structureTypes.COUPON:
        return <Coupon entity={entity} key={key} />
      case structureTypes.BLOG_POST_BODY:
        return <BlogPostBody entity={entity} key={key} />
      case structureTypes.BLOG_POST_CONTENT_PLACEHOLDER:
        return <BlogPostContentPlaceholder entity={entity} key={key} />
      case EntityTypeEnum.BlogPostContentPlaceholder:
        return <BlogPostContentPlaceholderNew entity={entity} key={key} />
      case structureTypes.BLOG_CONTENT_PLACEHOLDER:
        return <BlogContentPlaceholder entity={entity} key={key} />
      case EntityTypeEnum.BlogContentPlaceholder:
        return <BlogContentPlaceholderNew entity={entity} key={key} />
      case EntityTypeEnum.BlogPostTitle:
        return <BlogPostTitleNew entity={entity} key={key} />
      case EntityTypeEnum.BlogPostCategories:
        return <BlogPostCategoriesNew entity={entity} key={key} />
      case EntityTypeEnum.BlogPostListing:
        return <BlogPostListingNew entity={entity} key={key} />
      case EntityTypeEnum.LatestBlogPosts:
        return <LatestBlogPostsNew entity={entity} key={key} />
      case EntityTypeEnum.BlogPostDate:
        return <BlogPostDateNew entity={entity} key={key} />
      case EntityTypeEnum.Menu:
        return <Menu entity={entity} key={key} />
      case EntityTypeEnum.LanguageSwitcher:
        return <NewLanguageSwitcher entity={entity} key={key} />
      case structureTypes.LANGUAGE_SWITCHER:
        return <LanguageSwitcher entity={entity} key={key} />
      case structureTypes.TWO_STEP_PAYMENT_FORM:
        return <TwoStepPaymentForm entity={entity} key={key} />
      case EntityTypeEnum.HorizontalLine:
        return <NewHorizontalLine entity={entity} key={key} />
      case EntityTypeEnum.TwitterTweetButton:
        return <TwitterTweetButton entity={entity} key={key} />
      case structureTypes.SURVEY:
        return <Survey entity={entity} key={key} />
      case EntityTypeEnum.Survey:
        return <SurveyNew entity={entity} key={key} />
      case EntityTypeEnum.Carousel:
      case structureTypes.CAROUSEL:
        return <Carousel entity={entity} key={key} />
      case EntityTypeEnum.Faq:
        return <Faq entity={entity} key={key} />
      case EntityTypeEnum.PricePlanCalculator:
        return <PricePlanCalculator entity={entity} key={key} />
      case EntityTypeEnum.WebinarRegistrationDate:
        return <WebinarRegistrationDate entity={entity} key={key} />
      case EntityTypeEnum.Text:
        return <TextNew entity={entity} key={key} />
      case EntityTypeEnum.Headline:
        return <Headline entity={entity} key={key} />
      case EntityTypeEnum.BlogPostImage:
        return <BlogPostImage entity={entity} key={key} />
      case EntityTypeEnum.ContentTable:
        return <ContentTable entity={entity} key={key} />
      case EntityTypeEnum.ContactUs:
        return <ContactUs entity={entity} key={key} />
      case EntityTypeEnum.ContactUsField:
      case EntityTypeEnum.Field:
        return <ContactUsField entity={entity} key={key} />
      case EntityTypeEnum.ContactUsButton:
        return <ContactUsButton entity={entity} key={key} />
      case EntityTypeEnum.Recaptcha:
      case EntityTypeEnum.ContactUsRecaptcha:
        return <Recaptcha entity={entity} key={key} />
      case EntityTypeEnum.OptInRecaptcha:
        return <OptInRecaptcha entity={entity} key={key} />
      case EntityTypeEnum.TextArea:
        return <TextArea entity={entity} key={key} />
      case EntityTypeEnum.Breadcrumbs:
        return <Breadcrumbs entity={entity} key={key} />
      case EntityTypeEnum.Attachments:
        return <Attachments entity={entity} key={key} />
      case EntityTypeEnum.Video:
        return <VideoNew entity={entity} key={key} />
      case EntityTypeEnum.Image:
        return <Image entity={entity} key={key} />
      case EntityTypeEnum.LatestBlogPostsCarousel:
        return <LatestBlogPostsCarousel entity={entity} key={key} />
      case EntityTypeEnum.Audio:
        return <AudioNew entity={entity} key={key} />
      case EntityTypeEnum.CustomerType:
        return <CustomerTypeNew entity={entity} key={key} />
      case EntityTypeEnum.WebinarSessionVideo:
        return <WebinarSessionVideoNew entity={entity} key={key} />
      case EntityTypeEnum.Comments:
        return <div /> // just to not fail blocks creation, will be removed
      case EntityTypeEnum.BlogCategoryTitle:
        return <BlogCategoryTitle entity={entity} key={key} />
      case EntityTypeEnum.BlogCategoryDescription:
        return <BlogCategoryDescription entity={entity} key={key} />
      case EntityTypeEnum.RawHtml:
        return <NewRawHtml entity={entity} key={key} />
      case EntityTypeEnum.ShippingFees:
        return <ShippingFees entity={entity} key={key} />
      case EntityTypeEnum.Agreement:
        return <NewAgreement entity={entity} key={key} />
      case EntityTypeEnum.OrderBump:
        return <OrderBump entity={entity} key={key} />
      default:
        throw new Error(`unknown type ${entity.type} of node id = ${entity.id}`)
    }
  }
}
