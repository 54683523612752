import { useRollbar } from '@rollbar/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InternalError } from 'common/errors'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { buyMainOffer, buyOfferBump } from 'publisher/api/mercadoPagoApi'
import {
  isMercadoError,
  getProcessedMercadoErrorCauses,
  isMercadoErrorCausesArray,
} from 'publisher/context/mercadoPago/utils/initCardForm'
import { useMercadoPago } from 'publisher/context/mercadoPagoContext'
import usePaymentSubmit from 'publisher/hooks/payment/usePaymentSubmit'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import { ThreeDsInfo } from 'publisher/paymentProcessors/mercadoPago/components/ThreeDsModal'
import { usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

export default function useMercadoPagoPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const { errors, setErrors, isLoading, setLoading, submit } =
    usePaymentSubmit(entity)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const { generatePaymentData } = useMercadoPago()

  const handleSubmit = async (
    e: React.SyntheticEvent,
    handleThreeDs: (
      threeDsInfo: ThreeDsInfo,
      orderBumpOffers: number[],
      redirect?: string,
    ) => void,
  ) => {
    e.preventDefault()

    setLoading(true)
    setErrors([])

    submit(
      async body => {
        const paymentData = await generatePaymentData()

        if (!paymentData) {
          setErrors([t('core.errors.something_went_wrong')])
          setLoading(false)
          return
        }

        const { data } = await buyMainOffer(pageId, purchaseProcessId, {
          payment_form: {
            ...body,
            token: paymentData.token,
            mercadoPagoPaymentMethod: paymentData.paymentMethodId,
            installments: paymentData.installments,
          },
        })

        if (data.threeDsInfo) {
          handleThreeDs(data.threeDsInfo, body.orderBumpOffers, data.redirect)
          return
        }

        if (data.redirect) {
          if (body.orderBumpOffers.length > 0) {
            const bumpPaymentData = await generatePaymentData()
            if (bumpPaymentData) {
              await buyOfferBump(pageId, purchaseProcessId, {
                bump_payment_form: {
                  token: bumpPaymentData.token,
                  mercadoPagoPaymentMethod: bumpPaymentData.paymentMethodId,
                  installments: bumpPaymentData.installments,
                },
              })
            }
          }

          window.location.assign(data.redirect)
          return
        }
      },
      error => {
        if (isMercadoError(error)) {
          setErrors(getProcessedMercadoErrorCauses(error.cause, t))
        } else if (isMercadoErrorCausesArray(error)) {
          setErrors(getProcessedMercadoErrorCauses(error, t))
        } else if (error instanceof InternalError) {
          rollbar.error('Mercado pago internal error', error)
        } else {
          rollbar.error('Mercado pago undefined server error', error as Error)
        }
      },
    )
  }

  return { errors, isLoading, setErrors, handleSubmit }
}
